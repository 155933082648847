import React, { useEffect, useState } from "react";
import ky from "ky";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { Box, Button, Container, Flex, Heading, Spinner, Text } from "theme-ui";
import { format } from "date-fns";
import { logHttpErrorDetails, sleep } from "../lib/utils";

type Props = {
  data: {
    markdownRemark: any;
  };
  location: string;
};

const CancelAppointmentPage = ({ data, location }: Props) => {
  const { frontmatter } = data.markdownRemark;

  const [appointmentId, setAppointmentId] = useState<null | string>(null);
  const [appointmentDate, setAppointmentDate] = useState<Date | null>(null);
  const [appointmentStore, setAppointmentStore] = useState<string | null>(null);
  const [appointmentComment, setAppointmentComment] = useState<string | null>(null);
  const [cancelState, setCancelState] = useState<"todo" | "query" | "success" | "failure">("todo");
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const appid = params.get("id");
    if (!appid) {
      setError(
        "Ungültige Termin-Identifikationsnummer. Bitte melden Sie sich telefonisch bei uns um Ihren Termin abzusagen."
      );
      return;
    }
    setAppointmentId(appid);

    const fetchData = async () => {
      let result: any = null;
      try {
        result = await ky
          .get(
            `${process.env.GATSBY_CALENDAR_API_URL}/${process.env.BACKEND_COMPANY_ID}/fetch_appointment`,
            {
              searchParams: { id: appid },
            }
          )
          .json();
      } catch (error) {
        console.log(error);
        logHttpErrorDetails(error);
        setError("Verbindungsfehler zum Online Kalender. Bitte versuchen Sie es später nochmal.");
        return;
      }
      if (result?.alreadyDone) {
        setCancelState("success");
      } else {
        setAppointmentDate(new Date((result?.date || 0) * 1000));
        setAppointmentStore(result?.store);
        setAppointmentComment(result?.comment);
      }
    };
    fetchData();
  }, []);

  const cancel = async () => {
    setError(null);
    setCancelState("query");
    if (!appointmentDate) {
      setError(
        "Ungültiges Datum. Bitte melden Sie sich telefonisch bei uns um Ihren Termin abzusagen."
      );
    }

    try {
      await ky.post(
        `${process.env.GATSBY_CALENDAR_API_URL}/${process.env.BACKEND_COMPANY_ID}/cancel_appointment`,
        {
          json: {
            id: appointmentId,
            date: appointmentDate.getTime() / 1000,
            store: appointmentStore,
            comment: appointmentComment,
          },
        }
      );
      setCancelState("success");
    } catch (error) {
      console.log(error);
      logHttpErrorDetails(error);
      setError("Verbindungsfehler zum Online Kalender. Bitte versuchen Sie es später nochmal.");
      setCancelState("failure");
    }
  };

  return (
    <Layout seo={{ title: frontmatter.title, noindex: true }}>
      <Container my={5} variant="ctiny">
        <Heading as="h1" variant="styles.h2" mb={4}>
          {frontmatter.title}
        </Heading>
        {!!appointmentDate && cancelState != "success" && (
          <React.Fragment>
            <Text>
              Ihr Termin in der Filiale {appointmentStore} ist eingetragen für{" "}
              {format(appointmentDate, "dd.MM.yyyy HH:mm")}.
            </Text>
            <Flex mt={4}>
              <Button
                variant="cta"
                sx={{ flex: "1" }}
                onClick={cancel}
                disabled={cancelState == "query"}
              >
                {cancelState == "query" ? (
                  <Spinner size={32} variant="styles.spinner" />
                ) : (
                  "Termin Absagen"
                )}
              </Button>
            </Flex>
            {!!error && (
              <Box mt={2}>
                <Text variant="smallText">{error}</Text>
              </Box>
            )}
          </React.Fragment>
        )}
        {cancelState == "success" && <Text>Termin wurde erfolgreich abgesagt!</Text>}
        {!appointmentDate && cancelState != "success" && (
          <Flex sx={{ justifyContent: "center" }}>
            <Spinner variant="styles.spinner"></Spinner>
          </Flex>
        )}
        {!appointmentDate && !!error && (
          <Box mt={2}>
            <Text variant="smallText">{error}</Text>
          </Box>
        )}
      </Container>
    </Layout>
  );
};

export default CancelAppointmentPage;

export const pageQuery = graphql`
  query CancelAppointmentPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "cancel-appointment-page" } }) {
      id
      frontmatter {
        title
      }
    }
  }
`;
